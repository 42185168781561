export default [
    {
        path: '/products',
        name: 'products-index',
        meta: { title: 'Produits' },
        component: () => import('@/views/products/Index.vue'),
        redirect: { name: 'products-form', params: { id: 'list' } },
        children: [
            {
                path: 'form/:id',
                name: 'products-form',
                params: { id: 'list' },
                meta: { title: 'Liste des produits' },
                component: () => import('@/views/products/ProductsManager.vue')
            },
            {
                path: 'prices-families',
                name: 'prices-families',
                meta: { title: 'Tarifs et familles' },
                component: () => import('@/views/products/PriceFamilyManager.vue')
            },
            {
                path: 'suppliers',
                name: 'suppliers',
                meta: { title: 'Fournisseurs' },
                component: () => import('@/views/products/SupplierList.vue')
            },
            {
                path: 'suppliers/:id',
                name: 'supplierDetails',
                meta: { title: 'Détails du Fournisseur' },
                component: () => import('@/views/products/SupplierEdit.vue')
            },
            {
                path: 'stocks',
                name: 'stocks',
                meta: { title: 'Stock du produit' },
                component: () => import('@/views/products/StockManager.vue')
            },
            {
                path: 'stocks/:id',
                name: 'stocksDetails',
                meta: { title: 'Stock du produit' },
                component: () => import('@/views/products/StockManager.vue')
            },
            {
                path: 'periods',
                name: 'periods',
                meta: { title: 'Gestion des périodes' },
                component: () => import('@/views/products/PeriodManager.vue')
            }
        ]
    }
]
