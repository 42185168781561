import './common'
import axios from 'axios'

export default {
    getDisplays: () => axios.get('/displays'),
    getDisplay: (id) => axios.get(`/displays/${id}`),
    postDisplay: (data) => axios.post('/displays', data),
    updateDisplay: (id, data) => axios.put(`/displays/${id}`, data),
    removeDisplay: (id) => axios.delete(`/displays/${id}`),

    getFile: (filename) => axios.get(`/file/${filename}`),
    postProductImage: (data) => axios.post('/products/image', data),
    deleteProductImage: (filename) => axios.delete(`/products/image/${filename}`),

    getMedia: (id) => axios.get(`/media/${id}`),
    postMedia: (data) => axios.post('/media', data),
    removeMedia: (id) => axios.delete(`/media/${id}`),

    getVideos: () => axios.get('/videos'),
    getPdfs: () => axios.get('/pdfs'),
    getImages: () => axios.get('/images'),

    getFiles: (id) => axios.get(`/file/${id}`),

    getActivityForPlanning: (moduleId) => axios.get(`/modules/${moduleId}/getActivityForPlanning`)
}
