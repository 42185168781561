import './common'
import axios from 'axios'

export default {
    getProducts: (params) => axios.get('/products', { params }),
    getProductsStats: (params) => axios.get('/products-stats', { params }),
    getProductsByIDs: (params) => axios.get('/products-by-ids', { params }),
    getPaginatedProducts: (params, cancelToken = null) => axios.get('/products-paginated', { params, cancelToken }),

    getFamilies: (params) => axios.get('/families', { params }),
    getCardTypes: () => axios.get('/card-types'),
    GetListSubscriptionType: () => axios.get('list-subscription-type'),
    getProductsStockConsumption: (params) => axios.get('/products-stock-consumption', { params }),
    pickFromStock: (data) => axios.post('/products-stock-consumption', data),
    getFamiliesTreeWithProducts: (params) => axios.get('/families-tree-with-products', { params }),

    getProduct: (id) => axios.get(`/products/${id}`),
    postProduct: (data) => axios.post('/products', data),
    putProduct: (productUUID, data) => axios.put(`/products/${productUUID}`, data),
    postFamily: (data) => axios.post('/families', data),
    putFamily: (familyUUID, data) => axios.put(`/families/${familyUUID}`, data),
    deletePricingFromPeriod: (data) => axios.delete('/pricing-period', { data }),

    AddOrUpdateProductStockParams: (data) => axios.post('/stock-params', data),
    DeleteLastStockEntry: (data) => axios.put('/stock-delete-last-entry', data),
    AddOrUpdateProductStock: (data) => axios.post('/stock', data),
    TransferStock: (data) => axios.post('/stock-transfer', data)

}
